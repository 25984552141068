import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';

const OrderReceipt: React.FC = () => (
  <Layout>
    <SEO title="Order Received" />
    <Container section>
      <TitleSection title="Order Received" subtitle="Thank You" center />
      <p className="mt-4 text-center w-full">
        Thank you for your order. I will contact you soon to finalize it.
      </p>
    </Container>
  </Layout>
);

export default OrderReceipt;
